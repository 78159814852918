import { useEffect } from "react";
import queryString from "query-string";

export const useUtmTracking = () => {
  useEffect(() => {
    // Ensure this runs only in the browser
    if (typeof window !== "undefined") {
      const parsedQuery = queryString.parse(window.location.search);

      const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term"];

      utmParams.forEach(param => {
        if (parsedQuery[param]) {
          localStorage.setItem(param, parsedQuery[param]);
        }
      });
    }
  }, []);
};